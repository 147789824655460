import React, { useState } from 'react';
import { Layout } from 'antd';
import { Sidebar, Header } from '../components';
import { MainContent } from './index';
import { colors } from '../utils';

const { Content } = Layout;

const Main = () => {
  const [fixedSider, setFixedSider] = useState<boolean>(true);
  const { primary200 } = colors;

  return (
    <Layout hasSider style={{ minHeight: '100vh', backgroundColor: primary200 }}>
      <Sidebar getFixedSider={setFixedSider} />
      <Layout style={{ marginLeft: fixedSider ? 80 : 0 }}>
        <Header />
        <Layout>
          <Content style={{ padding: 12, backgroundColor: primary200 }}>
            <MainContent />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Main;
