import React from 'react';
import './App.scss';
import 'antd/dist/reset.css';
import { Main } from './pages';

function App() {
  return (
    <div className="App">
      <Main />
    </div>
  );
}

export default App;
