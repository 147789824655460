import React from 'react';
import { Col, Row, Card } from 'antd';
import { colors } from '../utils';

const MainContent = () => {
  const { primary100 } = colors;
  return (
    <Row>
      <Col md={{ span: 18 }} xs={{ span: 24 }}>
        <Col span={24} style={{ padding: '0.5em' }}>
          <Card style={{ backgroundColor: primary100, borderColor: primary100, height: '25em' }} />
        </Col>
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} style={{ padding: '0.5em' }}>
            <Card style={{ backgroundColor: primary100, borderColor: primary100, height: '20em' }} />
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} style={{ padding: '0.5em' }}>
            <Card style={{ backgroundColor: primary100, borderColor: primary100, height: '20em' }} />
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} style={{ padding: '0.5em' }}>
            <Card style={{ backgroundColor: primary100, borderColor: primary100, height: '20em' }} />
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} style={{ padding: '0.5em' }}>
            <Card style={{ backgroundColor: primary100, borderColor: primary100, height: '20em' }} />
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 24 }} lg={{ span: 12 }} style={{ padding: '0.5em' }}>
            <Card style={{ backgroundColor: primary100, borderColor: primary100, height: '20em' }} />
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }} style={{ padding: '0.5em' }}>
            <Card style={{ backgroundColor: primary100, borderColor: primary100, height: '20em' }} />
          </Col>
        </Row>
      </Col>
      <Col md={{ span: 6 }} xs={{ span: 24 }}>
        <Col span={24} style={{ padding: '0.5em' }}>
          <Card style={{ backgroundColor: primary100, borderColor: primary100, height: '40em' }} />
        </Col>
        <Col span={24} style={{ padding: '0.5em' }}>
          <Card style={{ backgroundColor: primary100, borderColor: primary100, height: '20em' }} />
        </Col>
      </Col>
    </Row>
  );
};

export default MainContent;
