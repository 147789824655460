import React from 'react';
import { Layout, Col, Row, Button } from 'antd';
import { colors } from '../utils';

const { Header: LHeader } = Layout;

const Header = () => {
  const { primary200 } = colors;

  return (
    <LHeader style={{ backgroundColor: primary200, paddingTop: '1em', height: 'auto' }}>
      <Row justify={'space-between'} style={{ alignItems: 'center' }}>
        <Col xs={{ span: 24 }} sm={{ span: 12 }} style={{ display: 'flex' }}>
          <img src={require('../assets/images/logo.png')} alt={'logo'} style={{ height: '3.25em' }} />
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <Row justify={'end'}>
            <Col className="gray-button">
              <Button type={'primary'}>Sign up</Button>
            </Col>
            <Col>
              <Button type={'primary'} style={{ marginLeft: '1em' }}>
                Login
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </LHeader>
  );
};

export default Header;
