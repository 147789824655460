import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Layout, Col, Menu, Space } from 'antd';
import { LogoutOutlined, HomeOutlined, SettingOutlined, HeartOutlined, ApiOutlined, FlagOutlined, UserOutlined } from '@ant-design/icons';
import { colors } from '../utils';

const { Sider } = Layout;

const Sidebar = ({ getFixedSider }: { getFixedSider: Dispatch<SetStateAction<boolean>> }) => {
  const [selectedItem, setSelectedItem] = useState<string[]>(['home']);
  const [topMargin, setTopMargin] = useState<string>('20px');
  const [fixedSider, setFixedSider] = useState<boolean>(true);
  const [menuHeight, setMenuHeight] = useState<string>('80vh');
  const { secondary, primary100 } = colors;

  useEffect(() => {
    getFixedSider(fixedSider);
  }, [fixedSider]);

  const topMenuItems = [
    {
      key: 'home',
      icon: <HomeOutlined style={{ fontSize: '20px' }} />,
    },
    {
      key: 'api',
      icon: <ApiOutlined style={{ fontSize: '20px' }} />,
    },
    {
      key: 'flag',
      icon: <FlagOutlined style={{ fontSize: '20px' }} />,
    },
    {
      key: 'heart',
      icon: <HeartOutlined style={{ fontSize: '20px' }} />,
    },
  ];

  return (
    <Sider
      breakpoint={'sm'}
      collapsedWidth="0"
      onCollapse={(collapsed) => setFixedSider(!collapsed)}
      width={80}
      style={{ height: '100vh', position: 'fixed', left: 0, top: 0, bottom: 0, backgroundColor: primary100, zIndex: 100 }}
      zeroWidthTriggerStyle={{
        backgroundColor: primary100,
        color: secondary,
        top: '1em',
        zIndex: 100,
        position: 'absolute',
        boxShadow: '2px 2px 5px #02130D',
        borderBottomRightRadius: '8px',
        borderTopRightRadius: '8px',
      }}
      onBreakpoint={(broken) => {
        setMenuHeight(broken ? '70vh' : '80vh');
        setTopMargin(broken ? '10px' : '20px');
      }}
    >
      <Space style={{ height: '10%' }}>
        <Space style={{ padding: '0.75em', borderRadius: '8px', backgroundColor: '#E9C44799' }}>
          <UserOutlined style={{ fontSize: '28px', color: 'white' }} />
        </Space>
      </Space>
      <Menu
        className="margin-top-sm"
        mode="vertical"
        style={{ height: menuHeight, listStyle: 'none', border: '0', backgroundColor: primary100 }}
        onClick={(e) => setSelectedItem([e.key])}
        selectedKeys={selectedItem}
      >
        {topMenuItems.map(({ key, icon }) => (
          <Menu.Item key={key} style={{ marginTop: topMargin }}>
            {icon}
          </Menu.Item>
        ))}
      </Menu>
      <Col>
        <Col>
          <SettingOutlined style={{ fontSize: '20px', color: '#aeaeae' }} />
        </Col>
        <Col style={{ marginTop: '30px' }}>
          <LogoutOutlined style={{ fontSize: '20px', color: '#aeaeae' }} />
        </Col>
      </Col>
    </Sider>
  );
};

export default Sidebar;
